import { twMerge } from 'tailwind-merge'

const EventIcon = ({ className }: { className?: string }) => (
  <svg
    className={twMerge('h-[12px] w-[12px]', className)}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 0V0.5H0.5V11.5H11.5V0.5H9.5V0H8.5V0.5H3.5V0H2.5ZM1.5 1.5H2.5V2H3.5V1.5H8.5V2H9.5V1.5H10.5V2.5H1.5V1.5ZM1.5 3.5H10.5V10.5H1.5V3.5ZM4.5 4.5V5.5H5.5V4.5H4.5ZM6.5 4.5V5.5H7.5V4.5H6.5ZM8.5 4.5V5.5H9.5V4.5H8.5ZM2.5 6.5V7.5H3.5V6.5H2.5ZM4.5 6.5V7.5H5.5V6.5H4.5ZM6.5 6.5V7.5H7.5V6.5H6.5ZM8.5 6.5V7.5H9.5V6.5H8.5ZM2.5 8.5V9.5H3.5V8.5H2.5ZM4.5 8.5V9.5H5.5V8.5H4.5ZM6.5 8.5V9.5H7.5V8.5H6.5Z"
      fill="#0F0F0F"
    />
  </svg>
)

export default EventIcon
