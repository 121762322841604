import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'

import { useCurrentUser } from 'hooks/useCurrentUser'

export const SetupLaunchDarklyUser = () => {
  const ldClient = useLDClient()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (ldClient) {
      if (currentUser) {
        ldClient.identify({
          key: currentUser.id,
          // If you add custom values here, make sure to add them to the backend
          // launchdarkly context as well (app/services/feature_flags/client.rb)
          custom: {
            forceFlag: currentUser.forceFlag || '',
            isStaff: currentUser.profile.kind === 'staff',
            isCollective: currentUser.profile.kind === 'collective',
            accessPolicyKind: currentUser.accessPolicyKind,
            companyName: currentUser.companyName || ''
          }
        })
      } else {
        // this sets the ld:$anonUserId in the Browser's localStorage which we can use for individual targeting
        // in the logged-out state https://docs.launchdarkly.com/sdk/features/anonymous#javascript
        // TODO: see if should pass it along to ruby client via context cookies?
        ldClient.identify({
          key: 'anonymous',
          kind: 'user',
          anonymous: true
        })
      }
    }
  }, [ldClient, currentUser])

  return null
}
