import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'

interface displayToastProps {
  message: string | string[]
  onUndo?: () => void
  viewHref?: string
  viewText?: string
  ctaText?: string
  onCtaClick?: () => void
  onClose?: () => void
  toastId?: string
  multiline?: boolean
}

export const displaySuccessToast = ({
  message,
  onUndo,
  viewHref,
  viewText,
  ctaText,
  onCtaClick,
  onClose,
  toastId = 'reforge-toast',
  multiline = false
}: displayToastProps) =>
  displayToast(
    <ToastCard
      dataTest="success_toast"
      message={message}
      onUndo={onUndo}
      viewHref={viewHref}
      viewText={viewText}
      ctaText={ctaText}
      onCtaClick={onCtaClick}
      multiline={multiline}
    />,
    { ...toastOptions, onClose, toastId: toastId }
  )

export const displayErrorToast = ({
  message,
  onUndo,
  viewHref,
  viewText,
  ctaText,
  onCtaClick,
  onClose,
  toastId = 'reforge-toast',
  multiline = false
}: displayToastProps) =>
  displayToast(
    <ToastCard
      type="destructive"
      message={message}
      onUndo={onUndo}
      viewHref={viewHref}
      viewText={viewText}
      ctaText={ctaText}
      onCtaClick={onCtaClick}
      multiline={multiline}
    />,
    { ...toastOptions, onClose, toastId: toastId }
  )

export const displayInfoToast = ({
  message,
  onUndo,
  viewHref,
  viewText,
  ctaText,
  onCtaClick,
  onClose,
  toastId = 'reforge-toast',
  multiline = false
}: displayToastProps) =>
  displayToast(
    <ToastCard
      type="informational"
      message={message}
      onUndo={onUndo}
      viewHref={viewHref}
      viewText={viewText}
      ctaText={ctaText}
      onCtaClick={onCtaClick}
      multiline={multiline}
    />,
    { ...toastOptions, onClose, toastId: toastId }
  )

export const displayWarningToast = ({
  message,
  onUndo,
  viewHref,
  viewText,
  ctaText,
  onCtaClick,
  onClose,
  toastId = 'reforge-toast',
  multiline = false
}: displayToastProps) =>
  displayToast(
    <ToastCard
      type="warning"
      message={message}
      onUndo={onUndo}
      viewHref={viewHref}
      viewText={viewText}
      ctaText={ctaText}
      onCtaClick={onCtaClick}
      multiline={multiline}
    />,
    { ...toastOptions, onClose, toastId: toastId }
  )
