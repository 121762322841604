import { HTMLAttributes, KeyboardEvent, MouseEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'

export type TagColor =
  | 'primary'
  | 'green'
  | 'teal'
  | 'blue'
  | 'light'
  | 'gray'
  | 'pink'
  | 'orange'

export const VARIANT_CLASSES = {
  primary: 'bg-rb-gray-500 text-white',
  green: 'bg-rb-green-100 text-rb-green-500',
  teal: 'bg-rb-teal-200 text-white',
  blue: 'bg-rb-blue-200 text-white',
  light: 'bg-rb-blue-50 text-rb-gray-400 h-[22px]',
  gray: 'bg-rb-gray-100 text-rb-gray-400',
  pink: 'bg-rb-pink-50 text-rb-gray-400',
  orange: 'bg-rb-orange-50 text-rb-gray-400'
}

export interface TagProps {
  className?: string
  text?: string
  variant?: TagColor
  tooltipContent?: ReactNode
  contentAfter?: ReactNode
  contentBefore?: ReactNode
  href?: string // if provided, will set the variant to "light" as per design and disable tooltips
  onClick?: (e: MouseEvent | KeyboardEvent) => void
  dataTest?: string
}

export const Component = ({
  href,
  children,
  ...props
}: Omit<TagProps, 'dataTest'> & HTMLAttributes<HTMLDivElement | HTMLAnchorElement>) => {
  if (!href) {
    return <div {...props}>{children}</div>
  }

  return href.includes('http') ? (
    <a href={href} {...props}>
      {children}
    </a>
  ) : (
    <Link to={href} {...props}>
      {children}
    </Link>
  )
}
