import React, { ReactNode, createContext, useContext, useState } from 'react'

interface RefetchContextValue {
  fetchChecklistTasks: boolean
  setFetchChecklistTasks: (shouldRefetch: boolean) => void
}

// This context is used to be able to fetch & refetch the same data from different components across the app
export const RefetchContext = createContext<RefetchContextValue>({
  fetchChecklistTasks: false,
  setFetchChecklistTasks: () => {}
})

export const useRefetch = () => useContext(RefetchContext)

const RefetchProvider = ({ children }: { children: ReactNode }) => {
  const [fetchChecklistTasks, setFetchChecklistTasks] = useState(false)

  return (
    <RefetchContext.Provider value={{ fetchChecklistTasks, setFetchChecklistTasks }}>
      {children}
    </RefetchContext.Provider>
  )
}

export default RefetchProvider
