import React from 'react'

const EmptyCircleRadioIcon = ({
  className = 'w-5 h-5',
  circleClassName
}: {
  className?: string
  circleClassName?: string
}) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      className={circleClassName}
      x="0.75"
      y="0.75"
      width="18.5"
      height="18.5"
      rx="9.25"
      fill="white"
      stroke="#D3D2D3"
      strokeWidth="1.5"
    />
  </svg>
)

export default EmptyCircleRadioIcon
