import { format } from 'date-fns'

import { CohortEvent } from 'domains/CohortViewer/utils'
import { getModuleProgress } from 'domains/Concepts/ConceptCardList/utils'

import {
  CmsSectionContentType,
  CohortCmsModulePartsFragment,
  CohortDashboardCmsSectionPartsFragment,
  CohortDashboardCohortPartsFragment,
  CohortDashboardLessonPartsFragment,
  CohortDashboardScheduledWeekPartsFragment,
  CohortDashboardUserProgramPartsFragment,
  CohortEventSameInfoPartsFragment,
  CohortTeamWeekProgressCmsModulePartsFragment,
  CohortTeamWeekProgressScheduledWeekPartsFragment,
  CohortTeamWeekProgressUserPartsFragment,
  CohortViewerNewCohortPartsFragment,
  Event,
  Host,
  SeasonNameFieldsFragment,
  ShowCohortDashboardSeasonPartsFragment,
  UserCohort,
  UserProgram,
  UserProgramPartsFragment
} from 'gql'

import { prettyJoin } from 'utils/arrayUtils'
import {
  DAY_MONTH_ABBREV_FORMAT,
  formatInTimezone,
  getAbbreviatedTimezone,
  getCurrentTimezone,
  getTimezoneAbbreviation,
  isAfterDate,
  isBeforeDate,
  isBetweenDates,
  isDateNow,
  isDateToday,
  isDateXorLessDaysAfterToday,
  utcToNewTimezone
} from 'utils/date'
import { capitalizeFirstLetter } from 'utils/stringUtils'

import { CmsSectionProgress, ModuleProgress } from 'typings/scalars'

export const NO_ATTENDANCE_STATUS = 'Not Attending'
export const ATTENDED_STATUS = 'Attended'

export const INTRO_TOPIC_SLUG: string = 'intros-off-topic'
export const CONVERSATIONS_TOPIC_SLUG: string = 'conversations'

interface MeetingTime {
  count: number
  firstStartDate: string
  firstEndDate: string
}

interface MeetingTimeDate {
  day: string
  times: string[]
}

/**
 * Gets a human readable string of meeting times based on the eventTimes from a cohort
 * and the user's timezone.
 * @param {array} meetingTimes - cohort.eventTimes ([{ count: 6, firstStartDate: 'datestring', firstEndDate: 'datestring' }])
 * @param {string} timezone - user timezone
 * @returns 'Tuesdays at 8:30AM or 5:00PM' or 'Tuesdays at 11:30PM or Wednesdays at 8:00AM' depending on the timezone.
 */
export function getMeetingTimesInText(
  meetingTimes: MeetingTime[],
  timezone: string,
  fullDays = false,
  showTimezone = false
) {
  if (!meetingTimes || !meetingTimes.length) return ''

  const tz = getCurrentTimezone(timezone)

  const dates: MeetingTimeDate[] = []

  const dayFormat = fullDays ? 'EEEE' : 'EE'

  // Add times to each day found: [{ day: 'Tuesday', times: ['5:30PM']}].
  meetingTimes.forEach((m) => {
    const date = utcToNewTimezone(m.firstStartDate, tz)
    let day = format(date, dayFormat)
    if (fullDays && m.count > 1) day += 's'

    if (dates.find((d) => d.day === day)) {
      dates.find((d) => d.day === day)?.times.push(format(date, 'h:mm a'))
    } else {
      dates.push({ day, times: [format(date, 'h:mm a')] })
    }
  })

  let string = ''

  dates.forEach(({ day, times }, i) => {
    const startDate = meetingTimes[0].firstStartDate

    string += `${day} at `

    if (times.length > 1) {
      times.forEach((t, ii) => (string += ii < times.length - 1 ? `${t} or ` : t))
    } else {
      string += times[0]
    }

    if (showTimezone) {
      string += ` (${getTimezoneAbbreviation(tz, startDate)})`
    }

    if (dates.length > 1 && i < dates.length - 1) {
      string += ' or '
    }
  })

  return string
}

/**
 * Gets a human readable string of the dates & times of a single meeting based on events from a cohort
 * and the user's timezone.
 * @param {array} events - cohort.scheduledWeeks.events ([{ startsAtUtc: 'datestring' }])
 * @param {string} timezone - user timezone
 * @returns 'Tue, 13 March - 8.30 AM or 5:00 PM (PST)' or 'Tue, 13 March - 11:30 PM or Wed, 14 March - 8:00 AM (PST)' depending on the timezone.
 */
export function getMeetingDateAndTimesInText(events: Event[], timezone: string) {
  if (!events || !events.length) return ''

  const tz = getCurrentTimezone(timezone)
  const dates: MeetingTimeDate[] = []

  // Add times to each day found: [{ day: 'Tue, 13 March', times: ['5:30PM']}].
  events.forEach((event) => {
    const date = utcToNewTimezone(event.startsAtUtc, tz)
    const day = format(date, 'EEE, d MMMM')

    if (dates.find((d) => d.day === day)) {
      dates.find((d) => d.day === day)?.times.push(format(date, 'h:mm a'))
    } else {
      dates.push({ day, times: [format(date, 'h:mm a')] })
    }
  })

  let string = ''

  dates.forEach(({ day, times }, i) => {
    const startDate = events[0].startsAtUtc

    string += `${day} - `

    if (times.length > 1) {
      times.forEach((t, ii) => (string += ii < times.length - 1 ? `${t} or ` : t))
    } else {
      string += times[0]
    }
    string += ` (${getTimezoneAbbreviation(tz, startDate)})`

    if (dates.length > 1 && i < dates.length - 1) {
      string += ' or '
    }
  })

  return string
}

export function getDurationInWeeksText(duration: string) {
  if (!duration) return ''
  return `${duration} weeks`
}

export function formatSeasonName(season: SeasonNameFieldsFragment) {
  return `${capitalizeFirstLetter(season.name)} ${season.year}`
}

export function getDateInDays(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export function canUserCohortEnrollmentBeModified(
  enrollmentSeasonId: string,
  cohortSeasonId: string
) {
  return enrollmentSeasonId === cohortSeasonId
}

export function formatCohortOpensAt(opensAt: Date, timezone: string) {
  if (!opensAt) return ''

  const formattedOpensAt = formatInTimezone(opensAt, timezone, DAY_MONTH_ABBREV_FORMAT)

  return `${formattedOpensAt} ${getAbbreviatedTimezone(opensAt, timezone)}`
}

export function formatCohortHostNames(cohortHosts: Host[]) {
  const cohortHostNames = cohortHosts.map((host) => host.name) || []

  return prettyJoin(cohortHostNames, ', ', cohortHosts.length > 2 ? ', & ' : ' & ')
}

export const getLessonProgressPercent = (
  userProgress: ModuleProgress,
  lesson: CohortDashboardLessonPartsFragment
) => {
  if (userProgress[lesson.id] === ('completed' as string)) {
    return 100
  }

  const currentSectionProgress = userProgress[`${lesson.id}d`] as CmsSectionProgress
  return currentSectionProgress?.content_percent || 0
}

export const isLessonComplete = (
  userProgress: ModuleProgress,
  lesson: CohortDashboardLessonPartsFragment
) => {
  return userProgress[lesson.id] === ('completed' as string)
}

export const showCohortDashboardForCohortSeason = (
  season?: ShowCohortDashboardSeasonPartsFragment | null
) => {
  const showCohortViewerAt = season?.showCohortViewerAt
  return (
    !!showCohortViewerAt &&
    (isDateNow(showCohortViewerAt) || isAfterDate(showCohortViewerAt))
  )
}

export const filterForScheduledWeeksWithEvents = (
  cohort?: CohortDashboardCohortPartsFragment | CohortViewerNewCohortPartsFragment
) =>
  (cohort?.scheduledWeeks || []).filter((scheduledWeek) => !!scheduledWeek.events.length)

export const scheduledWeekIdToDisplay = (
  cohort?: CohortDashboardCohortPartsFragment | CohortViewerNewCohortPartsFragment,
  ignoreUrlParams?: boolean,
  hasCohortOffboardingStarted?: boolean
): string => {
  const isEndWeek = hasCohortOffboardingStarted
  const endWeekId = isEndWeek ? 'end' : ''

  const scheduledWeeksWithLessons = filterForScheduledWeeksWithEvents(cohort)

  if (!cohort) {
    return ''
  }

  const urlParams = new URLSearchParams(window.location.search)
  const week = urlParams.get('week')

  if (!ignoreUrlParams && week && !isNaN(Number(week))) {
    return scheduledWeeksWithLessons[parseInt(week)]?.id || ''
  }

  if (cohort.isOngoing) {
    return (
      scheduledWeeksWithLessons.find((scheduledWeek) => scheduledWeek.isCurrentWeek)
        ?.id || ''
    )
  }

  if (cohort.isUpcoming) {
    return scheduledWeeksWithLessons[0].id
  }

  return endWeekId
}

export const getUserProgress = (
  cmsModule: CohortCmsModulePartsFragment | CohortTeamWeekProgressCmsModulePartsFragment,
  activeProgram: UserProgramPartsFragment | CohortTeamWeekProgressUserPartsFragment
): ModuleProgress => {
  const moduleProgress = getModuleProgress(activeProgram, cmsModule.id)
  const userProgress = ((cmsModule.project
    ? moduleProgress[`${cmsModule.project.id}d`]
    : moduleProgress) || {}) as ModuleProgress

  return userProgress
}

/**
 * Gets the lesson info & progress info for the latest lesson that needs to be started or completed in the given
 * scheduled week.
 * @todo currently not the most efficient in the worst case scenario, need to think of any improvements that can be made here
 * @param {object} scheduledWeek - the scheduled week with lessons to check
 * @param {object} activeProgram - user's activeProgram object with module progress data
 * @returns {} if no lesson, { lessonSlug: string, parentSlug: string, moduleSlug: string, progress: number, isFirstLesson: boolean } otherwise
 */
export const getLessonToDisplay = (
  scheduledWeek: CohortDashboardScheduledWeekPartsFragment,
  activeProgram: UserProgram
): object => {
  if (!scheduledWeek || !activeProgram) return {}

  const modulesWithSections = scheduledWeek.orderedCmsModules.filter(
    (module) => !!module.cmsSections.length
  )

  const standardizedModuleSummaryWithSections = modulesWithSections.map((module) =>
    cohortDashboardStandardizedModuleWithSections(module)
  )

  const defaultLessonToDisplay = {
    parentSlug: '',
    lessonSlug: '',
    moduleSlug: '',
    progress: -1,
    isFirstLesson: false
  }
  let currentLessonToDisplay = defaultLessonToDisplay
  const firstLessonSlug =
    standardizedModuleSummaryWithSections?.[0]?.module?.cmsSections?.[0]?.children?.[0]
      ?.slug

  for (const cmsModuleSummary of standardizedModuleSummaryWithSections.reverse()) {
    const cmsModule = cmsModuleSummary.module

    const userProgress = getUserProgress(cmsModule, activeProgram)

    // filter out only the sections that have lessons
    const sectionsWithLessons = cmsModule.cmsSections.filter(
      (section) => !!section.children.length
    )

    for (const cmsSection of sectionsWithLessons.reverse()) {
      for (const lesson of [...cmsSection.children].reverse()) {
        const progress = getLessonProgressPercent(userProgress, lesson)

        if (currentLessonToDisplay.lessonSlug && progress === 100) {
          // previous iteration we found a lesson that was not started & this adjacent lesson has been completed
          // therefore short-circuit the loop & display this lesson
          return currentLessonToDisplay
        } else {
          // previous iteration we found a lesson that was not started simply because the user isn't up to that lesson yet
          // clear out the state
          currentLessonToDisplay = defaultLessonToDisplay
        }

        // lesson hasn't been started yet or is in progress
        if (progress === 0 || progress !== 100) {
          currentLessonToDisplay = {
            parentSlug: cmsSection.slug,
            lessonSlug: lesson.slug,
            moduleSlug: cmsModule.slug || '',
            progress,
            isFirstLesson: lesson.slug === firstLessonSlug
          }

          // if lesson is in progress, short-circuit the loop & display this lesson
          if (progress > 0) return currentLessonToDisplay
        }
      }
    }
  }

  return currentLessonToDisplay.lessonSlug ? currentLessonToDisplay : {}
}

export interface cohortDashboardStandardizedModuleWithSectionsResult {
  module: CohortCmsModulePartsFragment
  recapLesson?: CohortDashboardLessonPartsFragment | null
}

/**
 * This transforms modules with top-level lessons and lessons within sections, all into lessons within sections, so it can be handled in the CohortDashboard
 * It also gives the option to separate out the recap section/lesson completely and return it as a separate property
 * @param {CohortCmsModulePartsFragment} module - the CmsModule
 * @param {boolean} isolateRecap - whether to separate out the recap sections/lessons
 * @returns {cohortDashboardStandardizedModuleWithSectionsResult}
 */
export const cohortDashboardStandardizedModuleWithSections = (
  module: CohortCmsModulePartsFragment,
  isolateRecap: boolean = false
): cohortDashboardStandardizedModuleWithSectionsResult => {
  // we create our own section for any top level lessons - note this only exists in this context in memory - we don't store it in the DB
  const fakedSectionForTopLevelLessons = (
    module: CohortCmsModulePartsFragment,
    children: CohortDashboardLessonPartsFragment[]
  ): CohortDashboardCmsSectionPartsFragment => ({
    __typename: 'CmsSection' as const,
    id: `container-section-${children.map((lesson) => lesson.id).join('-')}`,
    name: module.name,
    estimatedTime: children.reduce((sum, lesson) => {
      return sum + lesson.estimatedTime
    }, 0),
    slug: '',
    href: '',
    contentType: 'Section' as CmsSectionContentType,
    children,
    published: true
  })

  const sections: CohortDashboardCmsSectionPartsFragment[] = []
  let currentTopLevelLessonGroup: CohortDashboardCmsSectionPartsFragment[] = []
  let recapLesson: CohortDashboardLessonPartsFragment | null = null

  module.cmsSections.forEach((section) => {
    if (section.contentType === 'Section') {
      // we have encountered a Section
      if (section.name.toLowerCase().includes('recap') && isolateRecap) {
        // we have encountered a Section that is a recap - skip it since recaps are displayed separately
        recapLesson =
          section.children.find((lesson) =>
            lesson.name.toLowerCase().includes('recap')
          ) || null
        return
      }

      if (currentTopLevelLessonGroup.length) {
        // Since we have encountered a Section, if we have any top-level lessons collected, create a fake section for them and add them to the list of
        // Sections to display
        sections.push(fakedSectionForTopLevelLessons(module, currentTopLevelLessonGroup))
        currentTopLevelLessonGroup = []
      }

      if (section.children.length) {
        // only display sections that have published lessons
        sections.push(section)
      }
    } else if (section.contentType === 'Lesson') {
      if (section.name.toLowerCase().includes('recap') && isolateRecap) {
        // we have encountered a Lesson that is a recap - skip it since recaps are displayed separately
        recapLesson = section
        return
      }
      currentTopLevelLessonGroup.push(section)
    }
  })

  if (currentTopLevelLessonGroup.length) {
    // This condition is met if we never encountered a Section - just top-level Lessons.  In this case, we will have one faked Section with a bunch of lessons in the module
    sections.push(fakedSectionForTopLevelLessons(module, currentTopLevelLessonGroup))
  }

  return {
    module: {
      ...module,
      cmsSections: sections
    },
    recapLesson // note we are assuming there is only one recap lesson per module
  }
}

export interface scheduledWeekProgressSummary {
  totalEstimatedTime: number
  totalLessons: number
  totalCompleteLessons: number
  totalLessonProgressPercent: number
}

/**
 * Gets overall progress summary for the entire scheduled week
 * @param {CohortDashboardScheduledWeekPartsFragment} scheduledWeek - the scheduled week with lessons to check
 * @param {CohortDashboardUserProgramPartsFragment} activeProgram - user's activeProgram object with module progress data
 * @returns {scheduledWeekProgressSummary}
 */
export const getScheduledWeekProgressSummary = (
  scheduledWeek:
    | CohortDashboardScheduledWeekPartsFragment
    | CohortTeamWeekProgressScheduledWeekPartsFragment,
  activeProgram:
    | CohortDashboardUserProgramPartsFragment
    | CohortTeamWeekProgressUserPartsFragment
): scheduledWeekProgressSummary | null => {
  if (!scheduledWeek || !activeProgram) return null

  let totalLessons = 0
  let totalCompleteLessons = 0
  let totalLessonProgressPercentRaw = 0
  let totalEstimatedTime = 0

  const modulesWithSections = scheduledWeek.orderedCmsModules.filter(
    (module) => !!module.cmsSections.length
  )

  const moduleSummaries = modulesWithSections.map((module) =>
    cohortDashboardStandardizedModuleWithSections(module)
  )

  moduleSummaries.forEach((moduleSummary) => {
    const module = moduleSummary.module
    const userProgress = getUserProgress(module, activeProgram)

    module.cmsSections.forEach((section) => {
      totalEstimatedTime += section.estimatedTime

      section.children.forEach((lesson) => {
        totalLessons += 1
        const lessonProgress = getLessonProgressPercent(userProgress, lesson)

        totalLessonProgressPercentRaw += lessonProgress
        if (lessonProgress === 100) {
          totalCompleteLessons += 1
        }
      })
    })
  })

  const totalLessonProgressPercent = totalLessonProgressPercentRaw
    ? totalLessonProgressPercentRaw / totalLessons
    : 0

  return {
    totalEstimatedTime,
    totalLessons,
    totalCompleteLessons,
    totalLessonProgressPercent
  }
}

export const isRsvpdToCohortEventInfo = (eventInfo: CohortEventSameInfoPartsFragment) =>
  eventInfo.attendingStatus && eventInfo.attendingStatus !== NO_ATTENDANCE_STATUS

export const findRsvpdCohortEventInfo = (event: CohortEvent) =>
  event?.sameEventInfo.find((eventInfo) => isRsvpdToCohortEventInfo(eventInfo))

export const isCohortEventHappeningNow = (
  eventInfo?: CohortEventSameInfoPartsFragment
) => {
  if (!eventInfo) {
    return false
  }

  return (
    !!eventInfo &&
    (isDateNow(eventInfo?.startsAtUtc) ||
      isDateNow(eventInfo?.endsAtUtc) ||
      isBetweenDates(eventInfo?.startsAtUtc, eventInfo?.endsAtUtc))
  )
}

export const isCohortEventHappeningLaterToday = (
  eventInfo?: CohortEventSameInfoPartsFragment
) =>
  !!eventInfo && isDateToday(eventInfo.startsAtUtc) && isBeforeDate(eventInfo.startsAtUtc)

export const isCohortEventHappening1orLessDaysFromToday = (
  eventInfo?: CohortEventSameInfoPartsFragment
) => !!eventInfo && isDateXorLessDaysAfterToday(eventInfo.startsAtUtc, 1)

export const isCohortEventHappening3orLessDaysFromToday = (
  eventInfo?: CohortEventSameInfoPartsFragment
) => !!eventInfo && isDateXorLessDaysAfterToday(eventInfo.startsAtUtc, 3)

export const hasCohortEventEnded = (eventInfo: CohortEventSameInfoPartsFragment) =>
  isAfterDate(eventInfo.endsAtUtc)

export const startsAtTo1DayAfterPacificAtMidnight = (startsAt: string) => {
  // for any numbered week, for interventions the beginning of the week is considered the day after the scheduled week starts at (at midnight pacific),
  // because the actual scheduled week starts at is right after the event for the last week has ended
  const startsAtDate = Date.parse(startsAt)
  const startsAtPacific = utcToNewTimezone(startsAtDate, 'America/Los_Angeles')
  const startsAtPacific1DayAfter = new Date(startsAtPacific)
  startsAtPacific1DayAfter.setDate(startsAtPacific1DayAfter.getDate() + 1)
  const startsAtPacific1DayAfterAtMidnight = new Date(startsAtPacific1DayAfter)
  startsAtPacific1DayAfterAtMidnight.setHours(0, 0, 0, 0)

  return startsAtPacific1DayAfterAtMidnight
}

export const isBeginningOfScheduledWeek = (
  scheduledWeekStartsAt: string,
  isKickoffWeek: boolean
) => {
  const startsAtDate = Date.parse(scheduledWeekStartsAt)
  const startsAtDate3DaysAfter = new Date(startsAtDate)
  startsAtDate3DaysAfter.setDate(startsAtDate3DaysAfter.getDate() + 3)

  if (isKickoffWeek) {
    // for kickoff week, for interventions the beginning of the week is considered right after the scheduled week starts at
    return isBetweenDates(scheduledWeekStartsAt, startsAtDate3DaysAfter.toUTCString())
  }
  const dateNowPacific = utcToNewTimezone(new Date(), 'America/Los_Angeles')

  return (
    dateNowPacific >= startsAtTo1DayAfterPacificAtMidnight(scheduledWeekStartsAt) &&
    isBeforeDate(startsAtDate3DaysAfter.toUTCString())
  )
}

export const eventForLink = (event: CohortEvent) => {
  const attendingEvents = event.sameEventInfo.filter(
    (eventInfo) =>
      eventInfo.attendingStatus && eventInfo.attendingStatus !== NO_ATTENDANCE_STATUS
  )

  // If RSPVd 'Attending' to one event time: link to RSVPd event (even if it is in the past and another one is upcoming - user can always change their RSVP to the upcoming event)
  if (attendingEvents.length === 1) {
    return attendingEvents[0]
  }

  // If RSVPd to both event times:
  // Link to the soonest upcoming RSVPd event if there is one; otherwise link to the latest RSVPd event
  if (attendingEvents.length > 1) {
    const upcomingRsvpdEvent = attendingEvents.find((eventInfo) => !eventInfo.past)
    return upcomingRsvpdEvent || attendingEvents[attendingEvents.length - 1]
  }

  // We are RSVPd to neither event time, so link to upcoming event if there is one; otherwise link to the latest event
  return (
    event.sameEventInfo.find((eventInfo) => !eventInfo.past) ||
    event.sameEventInfo[event.sameEventInfo.length - 1]
  )
}

export const downloadCohortCertificateOfCompletion = (userCohort: UserCohort) => {
  if (userCohort?.cohortCompletionCertificate?.certificateUrl) {
    const url = userCohort?.cohortCompletionCertificate.certificateUrl
    const a: HTMLAnchorElement = document.createElement('a')
    a.href = url
    a.download = 'Cohort Completion Certificate.png'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
